<template>
  <DefaultTemplate :showStep="formFlag">
    <div class="depositFunds-box">
      <div class="form-box" v-if="formFlag">
        <el-form
          label-position="top"
          :model="internationalForm"
          ref="internationalForm"
          status-icon
          :rules="internationalRules"
        >
          <div class="box_top">
            <div class="box_top_left box">
              <p>{{ $t('deposit.intSwift.header') }}</p>
              <DepositInfo :instData="instData" :showNote="showNote"></DepositInfo>
              <strong>{{ $t('deposit.default.deposit') }}</strong>
              <div class="info">
                <p>
                  {{ $t('deposit.intSwift.desc', { platform: $config.info.fullName }) }}
                </p>
                <p>{{ $t('deposit.intSwift.options') }}</p>
              </div>
              <el-form-item :label="$t('common.field.accNum')" prop="accountNumber">
                <el-select
                  v-model="internationalForm.account"
                  :no-data-text="$t('common.field.noEligibleAcc')"
                  data-testid="accountNumber"
                >
                  <el-option
                    v-for="item in internationalForm.accountNumberOptions"
                    :key="item.value"
                    :value="item.value"
                    :data-testid="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <!-- BANK DETAILS -->
              <component
                :is="bankInfoComponent"
                :isShow="isShow"
                :currency="internationalForm.currency"
                type="payment2"
              ></component>
            </div>

            <div class="box_top_right">
              <div class="logo circle international"></div>
            </div>
          </div>
          <div class="box_bottom box">
            <div class="warn_info">
              <p>
                {{
                  $t('deposit.intSwift.refInfo', {
                    prefix: $t('deposit.intSwift.refInfoPrefix'),
                    platform: $config.info.fullName
                  })
                }}
              </p>
            </div>
            <strong>{{ $t('deposit.default.sendReceipt') }}</strong>
            <div class="info">
              <p>{{ $t('deposit.intSwift.desc2') }}</p>
              <p>{{ $t('deposit.intSwift.bussDayInfo') }}</p>
            </div>
            <div class="form-list">
              <ul class="clearfix">
                <li>
                  <el-form-item :label="$t('common.field.accNum')" class="special_input">
                    <div class="special_input_demo">{{ internationalForm.accountNumber }}</div>
                  </el-form-item>
                </li>
                <li>
                  <el-form-item
                    :label="setAmtLabel(internationalForm.currency, internationalForm.amount)"
                    prop="amount"
                  >
                    <numeric-input
                      v-model="internationalForm.amount"
                      :currency="internationalForm.currency"
                      :precision="2"
                    ></numeric-input>
                  </el-form-item>
                </li>
              </ul>
              <ul class="clearfix">
                <li>
                  <el-form-item :label="$t('common.field.upload')" prop="uploadFile">
                    <vUpload :limit="6" v-on:updateFileInfo="updateFileInfo" data-testid="updateFileInfo"></vUpload>
                  </el-form-item>
                </li>
                <li>
                  <el-form-item :label="$t('common.field.notes')">
                    <el-input v-model="internationalForm.notes" data-testid="notes"></el-input>
                    <div class="warn_info">
                      <p v-html="$t('deposit.intSwift.tip')"></p>
                    </div>
                  </el-form-item>
                </li>
                <li>
                  <el-form-item class="button">
                    <el-button class="btn-blue" :loading="loading" @click="submitForm()" data-testid="submit">
                      {{ $t('common.button.submit') }}
                    </el-button>
                  </el-form-item>
                </li>
              </ul>
            </div>
          </div>
        </el-form>
      </div>
      <Result v-if="successFlag">{{ $t('deposit.default.successMsg') }}</Result>
    </div>
  </DefaultTemplate>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import vUpload from '@/components/vUpload';
import Result from '@/components/Result';
import DefaultTemplate from '@/components/template/deposit/DefaultTemplate';
import DepositInfo from '@/components/payment/DepositInfo.vue';
import mixin from '@/mixins/page/deposit';
import { apiQuery_mt_accounts, apiInternational_bank_payment_equals } from '@/resource';

export default {
  name: 'equalsMoney',
  components: { vUpload, NumericInput, Result, DefaultTemplate, DepositInfo },
  mixins: [mixin],
  data() {
    const validateAmount = (rule, value, callback) => {
      const currency = this.internationalForm.currency;
      if (!this.bankCurrency.includes(currency)) return;
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (currency !== 'USC' && parseFloat(value) < this.minimumLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', { minLimit: Math.ceil(this.minimumLimit), currency: currency })
          )
        );
      } else if (currency !== 'USC' && parseFloat(value) > this.maxLimit) {
        callback(new Error(this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimit, currency: currency })));
      } else if (currency === 'USC' && parseFloat(value) < this.minLimitByUSC) {
        callback(
          new Error(this.$t('common.formValidation.amtLarger', { minLimit: this.minLimitByUSC, currency: currency }))
        );
      } else if (currency === 'USC' && parseFloat(value) > this.maxLimitByUSC) {
        callback(
          new Error(this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimitByUSC, currency: 'USC' }))
        );
      } else {
        callback();
      }
    };

    const checkUploadFile = (rule, value, callback) => {
      if (!(this.fileList.length > 0)) {
        callback(new Error(this.$t('common.formValidation.fileReceipt')));
      }
      callback();
    };
    return {
      internationalForm: {
        account: '',
        accountNumberOptions: [],
        amount: '',
        accountNumber: '',
        currency: '',
        notes: ''
      },
      nigeriaSACurrency: ['USD', 'EUR', 'GBP', 'USC'],
      internationalRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ],
        uploadFile: [
          {
            validator: checkUploadFile,
            required: true,
            trigger: 'change'
          }
        ]
      },
      fileList: [],
      isShow: false,
      formFlag: true,
      successFlag: false,
      minimumLimit: 50,
      instData: [this.$t('deposit.reminder.inst14'), this.$t('deposit.reminder.inst15')],
      showNote: false
    };
  },
  watch: {
    'internationalForm.account'(value) {
      const values = value.split(' - ');
      this.internationalForm.accountNumber = values[0];
      this.internationalForm.currency = values[1];
      this.isShow = true;
    },
    'internationalForm.accountNumberOptions'(options) {
      // 預設第一筆
      if (options.length > 0) this.internationalForm.account = options[0].value;
    },
    'internationalForm.currency': {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.convertUSDToTargetCurrency(newValue);
        }
      }
    }
  },
  methods: {
    updateFileInfo(fileInfo) {
      this.fileList = fileInfo.fileList;
      this.$refs['internationalForm'].validateField('uploadFile');
    },
    submitForm() {
      this.$refs['internationalForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              if (result.data.data) {
                this.formFlag = false;
                this.successFlag = true;
              } else {
                this.loading = false;
                this.errorMessage(this.$t('deposit.default.failed'));
              }
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        } else {
          return false;
        }
      });
    },
    fetchTradingAccount() {
      apiQuery_mt_accounts({ supportedCurrencies: 'all' }).then(resp => {
        if (resp.data.code == 0) {
          this.internationalForm.accountNumberOptions = resp.data.data
            .filter(i => this.bankCurrency.includes(i.currency))
            .map(i => {
              return {
                accountNumber: i.mt4_account,
                value: i.mt4_account + ' - ' + i.currency,
                currency: i.currency
              };
            });
        }
      });
    },
    submitDeposit() {
      return apiInternational_bank_payment_equals(
        {
          //payment channel is 1, payment type is 2
          mt4Account: this.internationalForm.accountNumber,
          operateAmount: this.internationalForm.amount,
          applicationNotes: this.internationalForm.notes,
          currency: this.internationalForm.currency,
          fileList: this.fileList
        },
        this.token
      );
    }
  },
  mounted() {
    this.fetchTradingAccount();
  },
  computed: {
    bankInfoComponent() {
      return () => this.$config.componentLoader(this.regulator, 'deposit/InternationalBankInfo').component;
    },
    bankCurrency() {
      return this.$config.getIntBankTransferCurrency(this.regulator);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/internationalAndAu.scss';
</style>
